import ArrowDown24 from '@sats-group/icons/24/arrow-down';
import ArrowUp24 from '@sats-group/icons/24/arrow-up';
import React, { ReactNode, useState } from 'react';

import Collapse from '../../components/collapse/collapse';

import { SitemapNode as Props } from './web-admin-page.types';

const buttonStyles: React.HTMLAttributes<HTMLButtonElement>['style'] = {
  appearance: 'none',
  marginLeft: 10,
  border: 'none',
  padding: 0,
};

const headerStyle = {
  display: 'inline-flex',
  justifyContent: 'space-between',
  minWidth: 80,
};

const ListElement: React.FunctionComponent<
  React.PropsWithChildren<{ header: ReactNode }>
> = ({ children, header }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return children ? (
    <li style={{ marginTop: 5 }}>
      <div style={headerStyle}>
        {header}
        <button onClick={() => setIsExpanded(a => !a)} style={buttonStyles}>
          {isExpanded ? <ArrowUp24 /> : <ArrowDown24 />}
        </button>
      </div>
      <Collapse isOpen={isExpanded}>{children}</Collapse>
    </li>
  ) : (
    <li>{header}</li>
  );
};

/* eslint-disable react/no-multi-comp */
const SitemapNode: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  data = {},
  parentSlugs = [],
}) => {
  const toUrl = (slug: string) => '/' + [...parentSlugs, slug].join('/');

  return (
    <ul style={{ margin: 0 }}>
      {Object.entries(data).map(([key, children]) => {
        const link =
          typeof children === 'object' && children['/'] ? (
            <a href={toUrl(key)}>{key}</a>
          ) : typeof children === 'boolean' && key !== '/' ? (
            <a href={toUrl(key)}>{key}</a>
          ) : key !== '/' ? (
            key
          ) : null;

        const childNodes =
          typeof children === 'object' ? (
            <SitemapNode data={children} parentSlugs={[...parentSlugs, key]} />
          ) : null;

        return link || childNodes ? (
          <ListElement header={link} key={key}>
            {childNodes}
          </ListElement>
        ) : null;
      })}
    </ul>
  );
};

export default SitemapNode;
