import React from 'react';

import Logo from '../logo/logo';

import { CleanHeader as Props } from './clean-header.types';

const CleanHeader: React.FunctionComponent<Props> = ({ logo }) => (
  <header className="clean-header">
    <div className="clean-header__logo">
      <Logo theme={Logo.themes.red} {...logo} />
    </div>
  </header>
);

export default CleanHeader;
