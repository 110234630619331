import Facebook from '@sats-group/icons/32/facebook-padding';
import Instagram from '@sats-group/icons/32/instagram';
import React from 'react';

import ContentContainer from '../content-container/content-container';
import ElementInterpolator from '../element-interpolator/element-interpolator';
import Link from '../link/link';
import Logo from '../logo/logo';
import Text from '../../components/text/text';

import { Footer as Props, Icon } from './footer.types';

const icons: Record<Icon, React.FunctionComponent> = {
  facebook: Facebook,
  instagram: Instagram,
};

const Footer: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cookiesAndPrivacyLink,
  copyrightTemplate,
  logo,
  sections,
  socialMediaLinks,
}) => (
  <footer className="footer">
    <ContentContainer>
      <div className="footer__content-wrapper">
        <div className="footer__logo">
          {logo && <Logo theme={Logo.themes.beige} {...logo} />}
        </div>
        <div className="footer__content">
          <div className="footer__sections">
            {sections.map(section => (
              <div className="footer__section" key={section.title}>
                <Text
                  elementName="h2"
                  size={Text.sizes.large}
                  theme={Text.themes.medium}
                >
                  {section.title}
                </Text>
                <ul className="footer__links">
                  {section.links.map(link => (
                    <li key={link.href}>
                      <Text elementName="span">
                        <a className="footer__link" href={link.href}>
                          {link.text}
                        </a>
                      </Text>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="footer__social-media">
            {socialMediaLinks.map(({ link, icon }) => (
              <Link
                className="footer__social-media-icon"
                key={link.href}
                {...link}
              >
                {React.createElement(icons[icon], {})}
              </Link>
            ))}
          </div>
          <div className="footer__copyright">
            <Text size={Text.sizes.interface}>
              <ElementInterpolator
                template={copyrightTemplate}
                elements={{
                  cookiesAndPrivacy: (
                    <a
                      href={cookiesAndPrivacyLink.href}
                      key={cookiesAndPrivacyLink.href}
                    >
                      {cookiesAndPrivacyLink.text}
                    </a>
                  ),
                }}
              />
            </Text>
          </div>
        </div>
      </div>
    </ContentContainer>
  </footer>
);

export default Footer;
