import type _Core from '@adyen/adyen-web/dist/types/core';
import type { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import type _DropinElement from '@adyen/adyen-web/dist/types/components/Dropin';
import type AdyenCheckoutType from '@adyen/adyen-web';
import type { PaymentAction } from '@adyen/adyen-web/dist/types/types';
import { useEffect, useState } from 'react';

import { appendScript } from 'root/client/ts/append-script';
import { appendStylesheet } from 'root/client/ts/append-stylesheet';

export type ApiResponse = {
  action?: PaymentAction;
  pspReference?: string;
  resultCode: string;
};

export type AdyenState = {
  data: {
    billingAddress: unknown;
    browserInfo: unknown;
    paymentMethod: Record<string, unknown>;
  };
  [key: string]: unknown;
};

export type Core = _Core;
export type DropinElement = _DropinElement;
export type PaymentMethodsResponse = CoreOptions['paymentMethodsResponse'];

declare global {
  interface Window {
    AdyenCheckout?: typeof AdyenCheckoutType;
  }
}

/**
 * https://docs.adyen.com/online-payments/release-notes/?integration_type=web
 */
export const useAdyen = (): [isLoading: boolean, hasLoaded: boolean] => {
  const [state, setState] = useState({ isLoading: false, hasLoaded: false });

  useEffect(() => {
    if (window.AdyenCheckout) {
      setState({
        isLoading: false,
        hasLoaded: true,
      });
      return;
    }

    setState({
      isLoading: true,
      hasLoaded: false,
    });

    appendStylesheet(
      'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/5.55.1/adyen.css',
      {
        crossorigin: 'anonymous',
        integrity:
          'sha384-IuCgWQHiduOAT+8kjAx1zca6/0QOA6vzdX5urkq9cZHiP9OpNd7cwBBnXRyNb+bO',
      },
    );

    appendScript(
      'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/5.55.1/adyen.js',
      () => {
        setState({
          isLoading: false,
          hasLoaded: Boolean(window.AdyenCheckout),
        });
      },
      {
        crossorigin: 'anonymous',
        integrity:
          'sha384-VsQjaTz8qABHk/hSOClt3JMbMRcJegaDNps4nZsu833faiVeFppygi21iPDpbVWW',
        onError: () => {
          setState({
            isLoading: false,
            hasLoaded: Boolean(window.AdyenCheckout),
          });
        },
      },
    );
  }, []);

  return [state.isLoading, state.hasLoaded];
};
