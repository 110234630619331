import React, { useEffect, useRef, useState } from 'react';
import Transition from 'react-tiny-transition';

import Button from 'root/client/components/button/button';
import Message from 'root/client/components/message/message';
import Text from 'root/client/components/text/text';
import { subscribe } from 'root/client/ts/messages';
import { MessageEventDetail } from 'root/client/ts/messages.types';

const copy = <T,>(object: T): T => JSON.parse(JSON.stringify(object));

import { MessageList as Props } from './message-list.types';

const MessageList: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  busIds,
  closeText,
}) => {
  const timerRef = useRef<number>();
  const [messages, setMessages] = useState<MessageEventDetail[]>([]);

  const addMessage = (message: MessageEventDetail) =>
    setMessages(currentMessages => copy(currentMessages).concat([message]));

  const removeMessage = (index: number) => () =>
    setMessages(currentMessages =>
      copy(currentMessages).filter((_, i) => i !== index),
    );

  useEffect(() => {
    clearTimeout(timerRef.current);
    timerRef.current = Number(setTimeout(removeMessage(0), 5000));

    return () => clearTimeout(timerRef.current);
  }, [messages]);

  useEffect(() => {
    busIds.map(busId => subscribe(busId, addMessage));
  }, [busIds]);

  return (
    <div className="message-list">
      <Transition
        classNames={{
          beforeEnter: 'message-list--before-enter',
          entering: 'message-list--entering',
          beforeLeave: 'message-list--before-leave',
          leaving: 'message-list--leaving',
        }}
      >
        {messages.length === 0 ? null : (
          <ul className="message-list__list">
            {messages.map(({ message, type }, index) => (
              <li key={index + message + type}>
                <Message type={type}>
                  <div className="message-list__inner">
                    <Text size={Text.sizes.basic}>{message}</Text>
                    <Button onClick={removeMessage(index)} text={closeText} />
                  </div>
                </Message>
              </li>
            ))}
          </ul>
        )}
      </Transition>
    </div>
  );
};

export default MessageList;
