import cn from 'classnames';
import React from 'react';

import ContentContainer from '../content-container/content-container';
import Footer from '../footer/footer';
import Header from '../header/header';
import MessageList from '../message-list/message-list';

import { Layout as Props, Theme } from './layout.types';

const Layout: React.FunctionComponent<React.PropsWithChildren<Props>> & {
  themes: typeof Theme;
} = ({ children, footer, header, messageList, theme }) => (
  <div className={cn('layout', { [`layout--${theme}`]: theme })}>
    <Header {...header} />
    <main>{children}</main>
    {footer && (
      <div className="layout__footer">
        <Footer {...footer} />
      </div>
    )}
    <div className="layout__messages">
      <ContentContainer>
        <MessageList {...messageList} />
      </ContentContainer>
    </div>
  </div>
);

Layout.themes = Theme;

export default Layout;
