import React from 'react';

import CheckoutActions from 'root/client/components/checkout-actions/checkout-actions';
import CheckoutHeader from 'root/client/components/checkout-header/checkout-header';
import CheckoutSteps from 'root/client/components/checkout-steps/checkout-steps';
import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ContentContainer from 'root/client/components/content-container/content-container';
import Message from 'root/client/components/message/message';
import MessageWithLink from 'root/client/components/message-with-link/message-with-link';
import RegisterMemberInputs from 'root/client/components/register-member-inputs/register-member-inputs';

import { CheckoutRegisterMemberPage as Props } from './checkout-register-member-page.types';

const CheckoutRegisterMemberPage: React.FunctionComponent<Props> = ({
  checkoutActions,
  checkoutSteps,
  errors,
  layout,
  registerMemberInputs,
  registrationPreventingError,
  sessionTimeoutError,
  title,
}) => (
  <CleanLayout {...layout}>
    <ContentContainer>
      <CheckoutHeader title={title} />
      <CheckoutSteps {...checkoutSteps} />
    </ContentContainer>
    {errors.length ? (
      <ContentContainer width={ContentContainer.widths.slim}>
        <div className="checkout-register-member-page__errors">
          {errors.map(text => (
            <Message key={text} text={text} type={Message.types.error} />
          ))}
        </div>
      </ContentContainer>
    ) : null}

    {sessionTimeoutError ? (
      <ContentContainer
        width={ContentContainer.widths.slim}
        className="checkout-register-member-page__session-timeout-error"
      >
        <MessageWithLink {...sessionTimeoutError} />
      </ContentContainer>
    ) : null}

    {registrationPreventingError ? (
      <ContentContainer width={ContentContainer.widths.slim}>
        <Message
          text={registrationPreventingError}
          type={Message.types.error}
        />
      </ContentContainer>
    ) : (
      <CheckoutActions
        {...checkoutActions}
        width={ContentContainer.widths.slim}
      >
        <RegisterMemberInputs {...registerMemberInputs} />
      </CheckoutActions>
    )}
  </CleanLayout>
);

export default CheckoutRegisterMemberPage;
