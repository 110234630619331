import cn from 'classnames';
import Loading24 from '@sats-group/icons/24/loading';
import React, { useMemo, useRef, useState } from 'react';

import absoluteUrl from 'root/client/ts/absolute-url';
import apiHelper from 'root/client/ts/api-helper';
import Message from 'root/client/components/message/message';
import TabTrapper from 'root/client/components/tab-trapper/tab-trapper';
import {
  type AdyenState,
  type ApiResponse,
  type Core,
  type DropinElement,
  type PaymentMethodsResponse,
  useAdyen,
} from 'root/client/hooks/use-adyen';
import useAsyncEffect from 'root/client/hooks/use-async-effect';
import { addSlugs } from 'root/shared/add-slugs';
import { replaceQueryParameters } from 'root/shared/replace-query-parameters';

import { Payment as Props } from './payment.types';

const magicAdyenId = 'dropin';
const magic3dsId = 'payment-3ds-frame';

const Payment: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  clientKey,
  environment,
  expiredSessionMessage,
  locale,
  memberId,
  payEndpoint,
  paymentDetailsEndpoint,
  paymentMethods,
  returnUrl,
  responseErrorMessages,
  sessionId,
  sessionIdQueryParameter,
  staleQueryParameter,
  successRedirectUrl,
  transactionIdQueryParameter,
  translations,
  unknownErrorMessage,
  unknownResponseMessage,
}) => {
  const [isAdyenLoading, hasAdyenLoaded] = useAdyen();
  const [is3ds2, setIs3ds2] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSettingAdditionalDetails, setIsSettingAdditionalDetails] =
    useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const checkoutRef = useRef<Core>();

  const show3ds2 = () => {
    // NOTE: Disables page scrolling
    document.body.style.position = 'fixed';
    setIs3ds2(true);
  };

  const hide3ds2 = () => {
    document.body.style.position = '';
    setIs3ds2(false);
  };

  const pushErrorMessage = (text: string) =>
    setErrorMessages(messages => {
      const newMessages = Array.from(messages);
      newMessages.push(text);
      return newMessages;
    });

  const handleError = (error: Error) => {
    console.error(error);
    hide3ds2();
    pushErrorMessage(unknownErrorMessage);
  };

  const handleExpiredSession = apiHelper.overrideStatus({
    422: () => {
      pushErrorMessage(expiredSessionMessage);
      return true;
    },
  });

  // NOTE: onAdditionalDetails and onSubmit share the response handler on purpose.
  // See https://docs.adyen.com/checkout/drop-in-web
  const handleResponse = (response: ApiResponse, dropin: DropinElement) => {
    if (!checkoutRef.current) {
      // NOTE: This should never happen
      return;
    }

    if (response.action) {
      switch (response.action.type) {
        case 'threeDS2':
        case 'threeDS2Challenge':
        case 'threeDS2Fingerprint': {
          // NOTE: These types are handled separately from the default,
          // in order to be able to size the 3DS window
          if (!checkoutRef.current) {
            // NOTE: This should never happen
            break;
          }
          show3ds2();
          checkoutRef.current
            .createFromAction(response.action, {
              challengeWindowSize: '05', // NOTE: '05' is 100% * 100%
            })
            .mount(`#${magic3dsId}`);
          break;
        }
        default: {
          dropin.handleAction(response.action);
          break;
        }
      }

      return;
    }

    switch (response.resultCode) {
      case 'Authorised': {
        history.replaceState(
          null,
          document.title,
          replaceQueryParameters(window.location.href, {
            [staleQueryParameter]: staleQueryParameter,
          }),
        );
        window.location.href = replaceQueryParameters(successRedirectUrl, {
          [sessionIdQueryParameter]: sessionId,
          [transactionIdQueryParameter]: response.pspReference,
        });
        break;
      }
      default: {
        hide3ds2();
        pushErrorMessage(
          responseErrorMessages[response.resultCode] || unknownResponseMessage,
        );
        break;
      }
    }
  };

  const shouldShowSpinner = useMemo(
    () => isAdyenLoading || isSettingAdditionalDetails || isSubmitting,
    [isAdyenLoading, isSettingAdditionalDetails, isSubmitting],
  );

  useAsyncEffect(async () => {
    if (!hasAdyenLoaded || !window.AdyenCheckout) {
      return;
    }

    const checkout = await window.AdyenCheckout({
      clientKey,
      environment,
      locale,
      translations,
      onAdditionalDetails: (state: AdyenState, dropin: DropinElement) => {
        setIsSettingAdditionalDetails(true);
        apiHelper
          .post(paymentDetailsEndpoint, {
            memberId,
            paymentDetails: state.data,
            sessionId,
          })
          .then(response => handleResponse(response, dropin))
          .catch(handleExpiredSession)
          .catch(handleError)
          .finally(() => setIsSettingAdditionalDetails(false));
      },
      onSubmit: (state: AdyenState, dropin: DropinElement) => {
        setIsSubmitting(true);
        apiHelper
          .post(payEndpoint, {
            billingAddress: state.data.billingAddress,
            browserInfo: state.data.browserInfo,
            memberId,
            originUrl: absoluteUrl(),
            // NOTE: URLs provided by our web server are relative. Adyen needs an absolute URL.
            redirectUrl: sessionId
              ? addSlugs(absoluteUrl(returnUrl), [sessionId]) + '?' // NOTE: If Adyen doesn't get the `?`, they construct an invalid URL
              : absoluteUrl(returnUrl),
            sessionId,
            ...state.data.paymentMethod,
          })
          .then(response => handleResponse(response, dropin))
          .catch(handleExpiredSession)
          .catch(handleError)
          .finally(() => setIsSubmitting(false));
      },
      paymentMethodsConfiguration: {
        card: {
          hasHolderName: true,
          holderNameRequired: true,
        },
      },
      // NOTE: This looks strange, but we really don't care what this is
      paymentMethodsResponse:
        paymentMethods as unknown as PaymentMethodsResponse,
    });
    checkout.create('dropin').mount(`#${magicAdyenId}`);
    checkoutRef.current = checkout;
  }, [hasAdyenLoaded]);

  return (
    <div className="payment">
      {shouldShowSpinner ? (
        <div className="payment__spinner">
          <div className="payment__spinner-icon">
            <Loading24 />
          </div>
        </div>
      ) : null}
      {errorMessages.length ? (
        <ul className="payment__errors">
          {errorMessages.map(text => (
            <li key={text}>
              <Message text={text} type={Message.types.error} />
            </li>
          ))}
        </ul>
      ) : null}
      <div data-adyen-mount-point id={magicAdyenId} tabIndex={-1} />
      <TabTrapper isActive={is3ds2}>
        <div
          aria-modal="true"
          role="dialog"
          className={cn('payment__3ds2-wrapper', {
            'payment__3ds2-wrapper--active': is3ds2,
          })}
        >
          <div
            className="payment__3ds2-frame"
            data-adyen-3ds2-frame
            id={magic3dsId}
          />
        </div>
      </TabTrapper>
    </div>
  );
};

export default Payment;
