import React from 'react';

import CheckoutHeader from 'root/client/components/checkout-header/checkout-header';
import CheckoutSteps from 'root/client/components/checkout-steps/checkout-steps';
import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ContentContainer from 'root/client/components/content-container/content-container';
import ExpandableClubs from 'root/client/components/expandable-clubs/expandable-clubs';

import { DropInSelectClubPage as Props } from './drop-in-select-club-page.types';

const DropInSelectClubPage: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ checkoutSteps, layout, list, title }) => (
  <CleanLayout {...layout}>
    <ContentContainer>
      <CheckoutHeader title={title} />
      <CheckoutSteps {...checkoutSteps} />
    </ContentContainer>
    <ContentContainer width={ContentContainer.widths.narrow}>
      <ExpandableClubs {...list} />
    </ContentContainer>
  </CleanLayout>
);

export default DropInSelectClubPage;
