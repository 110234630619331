import React from 'react';

import VisuallyButton from '../visually-button/visually-button';
import { sizes, themes } from '../visually-button/visually-button.types';

import type { LinkButton as Props } from './link-button.types';

const LinkButton: React.FunctionComponent<Props> & {
  sizes: typeof sizes;
  themes: typeof themes;
} = props => <VisuallyButton elementName="a" {...props} role="link" />;

LinkButton.sizes = VisuallyButton.sizes;
LinkButton.themes = VisuallyButton.themes;

export default LinkButton;
