import React from 'react';

import LinkCard from 'root/client/components/link-card/link-card';
import Text from 'root/client/components/text/text';

import { LinkCards as Props } from './link-cards.types';

const LinkCards: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  cards = [],
  title,
}) =>
  cards.length === 0 ? null : (
    <div className="link-cards">
      {title ? (
        <div className="link-cards__title">
          <Text size={Text.sizes.header3} variant={Text.variants.hero}>
            {title}
          </Text>
        </div>
      ) : null}
      <div>
        <div className="link-cards__list-wrapper">
          <ul className="link-cards__list">
            {cards.map(card => (
              <li key={card.href}>
                <LinkCard {...card} collapsed />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );

export default LinkCards;
