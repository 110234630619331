export enum Theme {
  red = 'red',
  white = 'white',
  beige = 'beige',
}

export type Logo = {
  href?: string;
  label: string;
  theme?: Theme;
};
