export enum AspectRatio {
  square = 'square',
  fourThree = '4-3',
  fill = 'fill',
  threeTwo = '3-2',
  sixteenNine = '16-9',
}

export type Image = {
  alt: string;
  aspectRatio?: AspectRatio;
  src: string;
};
