import React from 'react';

import Text from 'root/client/components/text/text';

import { Table as Props } from './table.types';

const Table: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  rows,
}) => {
  const headerRows = rows.slice(0, 1);
  const bodyRows = rows.slice(1, rows.length);

  return (
    <div className="table">
      <table className="table__single">
        <thead>
          {headerRows.map(({ cells, id }) => (
            <tr key={id}>
              {cells.map(({ hash, value }) => (
                <th key={hash}>
                  <Text size={Text.sizes.header3} theme={Text.themes.medium}>
                    {value}
                  </Text>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {bodyRows.map(({ cells, id }) => (
            <tr key={id}>
              {cells.map(({ hash, value }) => (
                <td key={hash}>
                  <Text size={Text.sizes.basic}>{value}</Text>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {bodyRows.map(({ cells, id }) => (
        <table className="table__multiple" key={id}>
          <tbody>
            {headerRows.map(headerRow => (
              <React.Fragment key={headerRow.id}>
                {headerRow.cells.map(({ hash, value }, index) => (
                  <tr key={hash}>
                    <th>
                      <Text
                        size={Text.sizes.header3}
                        theme={Text.themes.medium}
                      >
                        {value}
                      </Text>
                    </th>
                    <td>
                      <Text size={Text.sizes.basic}>{cells[index]?.value}</Text>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ))}
    </div>
  );
};

export default Table;
