import React from 'react';

import RichText from 'root/client/components/rich-text/rich-text';
import Text from 'root/client/components/text/text';

import { TextModule as Props } from './text-module.types';

const TextModule: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  body,
  intro,
  title,
  titleAddOn,
}) => {
  return (
    <div className="text-module">
      <div className="text-module__title">
        <Text
          elementName="h1"
          size={Text.sizes.header2}
          variant={Text.variants.hero}
        >
          {title}
        </Text>
        {titleAddOn}
      </div>
      <div className="text-module__introduction">
        <Text size={Text.sizes.large}>{intro}</Text>
      </div>
      <RichText className="text-module__body" {...body} />
    </div>
  );
};

export default TextModule;
