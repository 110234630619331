import React, { useEffect, useRef } from 'react';

import focusRef from 'root/client/ts/focus-ref';

import usePrevious from '../../hooks/use-previous';

import { TabTrapper as Props } from './tab-trapper.types';

const TabTrapper: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  className,
  isActive,
}) => {
  const wasActive = usePrevious(isActive);
  const contentRef: React.RefObject<HTMLDivElement> = useRef(null);
  const previouslyFocusedElement = useRef<HTMLElement | null>(null);

  const focusContent = () => focusRef(contentRef);

  useEffect(() => {
    if (!wasActive && isActive) {
      previouslyFocusedElement.current = document.activeElement as HTMLElement;
      focusContent();
    }

    if (wasActive && !isActive) {
      focusRef(previouslyFocusedElement);
    }
  }, [isActive]);

  // NOTE: This happens on unmount only
  useEffect(() => () => focusRef(previouslyFocusedElement), []);

  return (
    <>
      {isActive && <div onFocus={focusContent} tabIndex={0} />}
      <div className={className} ref={contentRef} tabIndex={-1}>
        {children}
      </div>
      {isActive && <div onFocus={focusContent} tabIndex={0} />}
    </>
  );
};

export default TabTrapper;
