import React from 'react';

import CheckoutHeader from 'root/client/components/checkout-header/checkout-header';
import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ContentContainer from 'root/client/components/content-container/content-container';
import Link from 'root/client/components/link/link';
import Message from 'root/client/components/message/message';

import { CheckoutPaymentErrorPage as Props } from './checkout-payment-error-page.types';

const CheckoutPaymentErrorPage: React.FunctionComponent<Props> = ({
  errors,
  layout,
  link,
  title,
}) => (
  <CleanLayout {...layout}>
    <ContentContainer>
      <CheckoutHeader title={title} />
    </ContentContainer>
    {errors.length ? (
      <ContentContainer width={ContentContainer.widths.slim}>
        <div className="checkout-payment-page__errors">
          {errors.map(text => (
            <Message key={text} text={text} type={Message.types.error} />
          ))}
        </div>
      </ContentContainer>
    ) : null}
    <ContentContainer width={ContentContainer.widths.slim}>
      <Link {...link} />
    </ContentContainer>
  </CleanLayout>
);

export default CheckoutPaymentErrorPage;
