import cn from 'classnames';
import React from 'react';

import type { LayeredIcon as Props } from './layered-icon.types';

const LayeredIcons: React.FunctionComponent<Props> = ({ icons }) => (
  <span className="layered-icon">
    {icons.map(({ color, icon }, index) => (
      <div
        className={cn('layered-icon__icon', `layered-icon__icon--${color}`)}
        key={index}
      >
        {icon}
      </div>
    ))}
  </span>
);
export default LayeredIcons;
