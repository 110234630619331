export enum Width {
  slim = 'slim',
  medium = 'medium',
  narrow = 'narrow',
  veryNarrow = 'very-narrow',
  wide = 'wide',
  full = 'full',
}

export type ContentContainer = {
  className?: string;
  width?: Width;
};
