import React from 'react';

import ContactOption from '../contact-option/contact-option';
import MemberChatTrigger from '../member-chat-trigger/member-chat-trigger';

import { ContactOptions as Props } from './contact-options.types';

const ContactOptions: React.FunctionComponent<Props> = ({ chat, entries }) => (
  <div className="contact-options">
    {entries.map(entry => (
      <ContactOption
        {...entry}
        children={
          entry.key === 'chat' && chat ? (
            <MemberChatTrigger {...chat} />
          ) : undefined
        }
        key={entry.key}
      />
    ))}
  </div>
);

export default ContactOptions;
