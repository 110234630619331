import Check36 from '@sats-group/icons/36/check';
import React from 'react';

import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ContentContainer from 'root/client/components/content-container/content-container';
import ContentSection from 'root/client/components/content-section/content-section';
import Hero from 'root/client/components/hero/hero';
import RichText from 'root/client/components/rich-text/rich-text';
import Text from 'root/client/components/text/text';

import { DropInPage as Props } from './drop-in-page.types';

const DropInPage: React.FunctionComponent<Props> = ({
  benefitsList,
  benefitsTitle,
  body,
  hero,
  layout,
  title,
}) => (
  <CleanLayout {...layout}>
    <div className="drop-in-page">
      <Hero {...hero} short={true} />
      <ContentSection
        actions={hero.actions}
        color={ContentSection.colors.primary}
      >
        <div className="drop-in-page__benefits">
          <ContentContainer width={ContentContainer.widths.wide}>
            <div className="drop-in-page__title">
              <Text
                elementName="h1"
                size={Text.sizes.header2}
                variant={Text.variants.hero}
              >
                {title}
              </Text>
            </div>
            <div className="drop-in-page__title">
              <Text elementName="h2" size={Text.sizes.large}>
                {benefitsTitle}
              </Text>
            </div>
            <ul className="drop-in-page__benefits-list">
              {benefitsList.map(item => (
                <li key={item} className="drop-in-page__benefits-list--items">
                  <span>
                    <Check36 />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </ContentContainer>
        </div>
      </ContentSection>
      <ContentSection
        actions={hero.actions}
        color={ContentSection.colors.secondary}
      >
        <div className="drop-in-page__body">
          <ContentContainer width={ContentContainer.widths.wide}>
            <RichText {...body} />
          </ContentContainer>
        </div>
      </ContentSection>
    </div>
  </CleanLayout>
);

export default DropInPage;
