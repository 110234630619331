import React from 'react';

import CheckoutActions from 'root/client/components/checkout-actions/checkout-actions';
import CheckoutHeader from 'root/client/components/checkout-header/checkout-header';
import CheckoutSteps from 'root/client/components/checkout-steps/checkout-steps';
import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ConfirmationSummary from 'root/client/components/confirmation-summary/confirmation-summary';
import ContentContainer from 'root/client/components/content-container/content-container';
import Message from 'root/client/components/message/message';
import Payment from 'root/client/components/payment/payment';

import { DropInPaymentPage as Props } from './drop-in-payment-page.types';

const DropInPaymentPage: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  checkoutActions,
  checkoutSteps,
  errors = [],
  layout,
  payment,
  paymentDisclaimer,
  summary,
  title,
}) => (
  <CleanLayout {...layout}>
    <ContentContainer>
      <CheckoutHeader title={title} />
      <CheckoutSteps {...checkoutSteps} />
    </ContentContainer>
    {errors.length ? (
      <ContentContainer>
        <div className="drop-in-payment-page__errors">
          {errors.map(text => (
            <Message key={text} text={text} type={Message.types.error} />
          ))}
        </div>
      </ContentContainer>
    ) : null}
    <CheckoutActions {...checkoutActions}>
      <div className="drop-in-payment-page__content">
        <div className="drop-in-payment-page__payment">
          <Payment {...payment} />
          {paymentDisclaimer ? (
            <div className="drop-in-payment-page__disclaimer">
              <Message text={paymentDisclaimer} type={Message.types.info} />
            </div>
          ) : null}
        </div>
        <div className="drop-in-payment-page__summary">
          <ConfirmationSummary {...summary} />
        </div>
      </div>
    </CheckoutActions>
  </CleanLayout>
);

export default DropInPaymentPage;
