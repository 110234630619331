import cn from 'classnames';
import React from 'react';

import { Container as Props } from './container.types';

const Container: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  size,
}) => (
  <div
    className={cn('container', {
      [`container--size-${size}`]: size,
    })}
  >
    {children}
  </div>
);

export default Container;
