import type mapboxgl from 'mapbox-gl';
import { useEffect, useState } from 'react';

import { appendScript } from 'root/client/ts/append-script';
import { appendStylesheet } from 'root/client/ts/append-stylesheet';

/**
 * https://docs.mapbox.com/mapbox-gl-js/guides/install/
 */
export const useMapbox = () => {
  const [mapbox, setMapbox] = useState<typeof mapboxgl>();

  useEffect(() => {
    if (mapbox) {
      return;
    }

    appendStylesheet(
      'https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.css',
    );

    appendScript(
      'https://api.mapbox.com/mapbox-gl-js/v3.0.1/mapbox-gl.js',
      () => setMapbox(window.mapboxgl),
    );
  }, []);

  return mapbox;
};
