import type {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactElement,
} from 'react';
import type { ValueOf } from 'type-fest';

export const themes = {
  primary: 'primary',
  primaryWhite: 'primary-white',
  secondary: 'secondary',
  secondaryWhite: 'secondary-white',
  tertiary: 'tertiary',
  complete: 'complete',
  cta: 'cta',
  ctaSecondary: 'cta-secondary',
  ctaSecondaryWhite: 'cta-secondary-white',
} as const;

export const sizes = {
  basic: 'basic',
  large: 'large',
  small: 'small',
} as const;

export type CommonProps = {
  elementName: string;
  icon?: ReactElement;
  iconOnly?: boolean;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  size?: ValueOf<typeof sizes>;
  testId?: string;
  text: string;
  theme?: ValueOf<typeof themes>;
  wide?: boolean;
};

export type VisuallyButtonButton = CommonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export type VisuallyButtonLink = CommonProps &
  AnchorHTMLAttributes<HTMLAnchorElement>;

// NOTE: If the element isn't `a` or `button` we don't restrict the type of the rest props
type VisuallyButtonOther = CommonProps & {
  [key: string]: any;
};

export type VisuallyButton =
  | ({ elementName: 'button' } & VisuallyButtonButton)
  | ({ elementName: 'a' } & VisuallyButtonLink)
  | ({ elementName: string } & VisuallyButtonOther);
