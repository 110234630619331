import React from 'react';

import Expander from 'root/client/components/expander/expander';
import RichText from 'root/client/components/rich-text/rich-text';

import { ExpandableText as Props } from './expandable-text.types';

const ExpandableText: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ content, title }) => (
  <div className="expandable-text">
    <Expander title={title}>
      <div className="expandable-text__content">
        <RichText {...content} />
      </div>
    </Expander>
  </div>
);

export default ExpandableText;
