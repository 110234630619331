import React from 'react';

import LogoBeige from 'root/client/components/logos/logo-beige';
import LogoRed from 'root/client/components/logos/logo-red';
import LogoWhite from 'root/client/components/logos/logo-white';

import { Logo as Props, Theme } from './logo.types';

const Logo: React.FunctionComponent<React.PropsWithChildren<Props>> & {
  themes: typeof Theme;
} = ({ href, label, theme = Theme.red }) =>
  React.createElement(
    href ? 'a' : 'div',
    {
      'aria-label': href ? label : undefined,
      className: 'logo',
      href,
    },
    theme === Theme.red ? (
      <LogoRed className="logo__image" />
    ) : theme === Theme.beige ? (
      <LogoBeige className="logo__image" />
    ) : (
      <LogoWhite className="logo__image" />
    ),
  );

Logo.themes = Theme;

export default Logo;
