import {
  MessageEventDetail,
  MessageListener,
  MessageType,
} from './messages.types';

export const publish = ({
  busId,
  message,
  type,
}: {
  busId: string;
  message: string;
  type: MessageType;
}) => {
  if (!message || !busId) {
    return;
  }

  window.dispatchEvent(
    new CustomEvent<MessageEventDetail>(busId, { detail: { message, type } }),
  );
};

const subscriber =
  (listener: MessageListener) => (event: CustomEvent<MessageEventDetail>) =>
    listener(event.detail);

export const subscribe = (busId: string, listener: MessageListener) => {
  if (!busId || !listener) return () => {};

  window.addEventListener(busId, subscriber(listener));
  return () => window.removeEventListener(busId, subscriber(listener));
};
