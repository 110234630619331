import React, { useRef, useState } from 'react';

import InputButton from 'root/client/components/input-button/input-button';
import Text from 'root/client/components/text/text';

import { Attachment as Props } from './attachment.types';

const Attachment: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  acceptedFileTypes,
  label,
  name,
  noAttachmentText,
  title,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const input: React.RefObject<HTMLInputElement> = useRef(null);

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files).map(file => file.name);
      setUploadedFiles([...files]);
    }
  };

  return (
    <div className="attachment">
      {title && (
        <div className="attachment__title">
          <Text elementName="h2" size={Text.sizes.small}>
            {title}
          </Text>
        </div>
      )}
      <div className="attachment__input-wrapper">
        <InputButton
          className="attachment__input"
          type="file"
          multiple
          accept={acceptedFileTypes}
          id="attachment-input"
          name={name}
          onChange={handleUpload}
          ref={input}
        >
          <Text size={Text.sizes.small}>{label}</Text>
        </InputButton>
        <div className="attachment__text">
          {uploadedFiles.length ? (
            <div>
              {uploadedFiles.map(file => (
                <div>
                  <Text size={Text.sizes.basic} key={file}>
                    {file}
                  </Text>
                </div>
              ))}
            </div>
          ) : (
            <span className="attachment__no-attachment-text">
              <Text size={Text.sizes.small}>{noAttachmentText}</Text>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default Attachment;
