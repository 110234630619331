import React, { FormEvent, useEffect, useState } from 'react';

import Button from '../../components/button/button';
import ContentContainer from '../../components/content-container/content-container';
import currentCommitHash from '../../codegen/current-git-commit-hash.codegen';
import Expander from '../../components/expander/expander';
import Layout from '../../components/layout/layout';
import Link from '../../components/link/link';
import Message from '../../components/message/message';
import Modal from '../../components/modal/modal';
import Text from '../../components/text/text';

import { WebAdminPage as Props } from './web-admin-page.types';
import SitemapNode from './sitemap-node';

const wait = (seconds: number) =>
  new Promise(resolve => setTimeout(resolve, seconds * 1000));

const WebAdminPage: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  contentful,
  data = {},
  dateTimeIso,
  environmentVariables = {},
  gitLog,
  layout,
  logOut,
  messages = [],
  sitemap,
  title,
}) => {
  const [isRefreshingData, setIsRefreshingData] = useState(false);
  const [copyState, setCopyState] = useState<string>();
  const [modalData, setModalData] = useState<any>();
  const [refreshMessage, setRefreshMessage] = useState<string>();
  const [dataBuildLocalTime, setDataBuildLocalTime] =
    useState('calculating...');

  useEffect(() => {
    setDataBuildLocalTime(new Date(dateTimeIso).toLocaleString());
  }, []);

  const checkDataJobStatus = async (
    url: string,
    jobname: string,
    currentTry = 0,
    maxTries = 30,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    if (currentTry >= maxTries) {
      setRefreshMessage(
        `❌Maximum number of status checks (${maxTries}) reached on ${url}`,
      );

      setIsRefreshingData(false);
      return;
    }

    await wait(currentTry * 0.5);

    setRefreshMessage(`⏳Checking status (${currentTry}/${maxTries})`);

    const result = await fetch(url);

    if (result.status === 202) {
      return checkDataJobStatus(url, jobname, currentTry + 1, maxTries);
    }

    if (result.ok) {
      setRefreshMessage(
        `✅Success! Data refreshed in (${currentTry}/${maxTries}) tries`,
      );
      setIsRefreshingData(false);
    } else {
      setRefreshMessage(
        `❌Status check failed (${currentTry}/${maxTries}) with status ${result.status} ${result.statusText}`,
      );
      setIsRefreshingData(false);
    }
  };

  const handleDataJobSubmission = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setRefreshMessage(`⏳Refreshing data`);
    setIsRefreshingData(true);

    //@ts-ignore
    const { action } = event.target;
    //@ts-ignore
    const { name, value } = event.nativeEvent.submitter;

    const body = new FormData();
    body.set(name, value);

    fetch(action, {
      method: 'post',
      body,
    })
      .then(result => {
        const location = result.headers.get('location');
        if (!location) {
          throw new Error('Missing location header');
        }

        checkDataJobStatus(location, value);
      })
      .catch(() => {
        setRefreshMessage('❌Something went wrong while refreshing data');
        setIsRefreshingData(false);
      });
  };

  const hideData = () => setModalData(undefined);
  const showData = (data: any) => () => setModalData(data);
  const copyData = (data: any) => () =>
    navigator.clipboard
      .writeText(data)
      .then(() => setCopyState('✅'))
      .catch(() => setCopyState('❌'))
      .finally(() => setTimeout(() => setCopyState(undefined), 5000));

  return (
    <Layout {...layout} theme={Layout.themes.primary}>
      <Modal
        hide={hideData}
        isVisible={!!modalData}
        theme={Modal.themes.centered}
        closeButtonText="Close"
      >
        <div style={{ alignItems: 'center', display: 'flex', margin: '1rem' }}>
          <Button
            onClick={copyData(modalData)}
            size={Button.sizes.small}
            text="📋 Copy"
          />
          <span style={{ marginLeft: '1rem' }}>{copyState}</span>
        </div>
        <div style={{ margin: '1rem' }}>
          <code>
            <pre>{modalData}</pre>
          </code>
        </div>
      </Modal>
      <ContentContainer>
        <style>
          {`
        table {
          width: 100%;
        }

        table td,
        table th {
          text-align: left;
          vertical-align: top;
        }
        `}
        </style>
        <div className="web-admin-page__header">
          <Text
            elementName="h1"
            size={Text.sizes.header2}
            variant={Text.variants.hero}
          >
            {title}
          </Text>
          <Link {...logOut} />
        </div>
        {messages.length === 0 ? null : (
          <ul className="web-admin-page__messages">
            {messages.map(message => (
              <li key={message.text}>
                <Message {...message} />
              </li>
            ))}
          </ul>
        )}
        <Expander areChildrenPadded={true} title="Refresh">
          <div className="web-admin-page__refresh-data">
            <Text theme={Text.themes.emphasis}>Job Status</Text>
            <div className="web-admin-page__refresh-job-status">
              {refreshMessage ? (
                <Text>{refreshMessage}</Text>
              ) : (
                <Text>No jobs currently running</Text>
              )}
            </div>
            <Text theme={Text.themes.emphasis}>Choose data to refresh</Text>
            <div>
              <form
                action="/api/admin-start-data-job"
                className="web-admin-page__refresh-data-selection-form"
                encType="application/x-www-form-urlencoded"
                onSubmit={handleDataJobSubmission}
                method="post"
              >
                <Button
                  type="submit"
                  name="job"
                  value="refresh-all"
                  disabled={isRefreshingData}
                  loading={isRefreshingData}
                  text="All data"
                  theme={Button.themes.secondary}
                />
                <Button
                  type="submit"
                  name="job"
                  value="refresh-contentful"
                  disabled={isRefreshingData}
                  loading={isRefreshingData}
                  text="Contentful"
                  theme={Button.themes.secondary}
                />
                <Button
                  type="submit"
                  name="job"
                  value="refresh-platform"
                  disabled={isRefreshingData}
                  loading={isRefreshingData}
                  text="Platform"
                  theme={Button.themes.secondary}
                />
                <Button
                  type="submit"
                  name="job"
                  value="refresh-transifex"
                  disabled={isRefreshingData}
                  loading={isRefreshingData}
                  text="Transifex"
                  theme={Button.themes.secondary}
                />
              </form>
            </div>
          </div>
        </Expander>
        <Expander areChildrenPadded={true} title="GitLog">
          <div className="web-admin-page__git-log">
            {gitLog &&
              gitLog.map(
                ({
                  date,
                  emoji,
                  hash,
                  message,
                  pr,
                  prUrl,
                  trackerId,
                  trackerUrl,
                }) => (
                  <div className="web-admin-page__git-log-entry" key={hash}>
                    <div className="web-admin-page__git-log-emoji">{emoji}</div>
                    <div className="web-admin-page__git-log-text">
                      <div>
                        {trackerId && trackerUrl ? (
                          <React.Fragment>
                            <a
                              href={trackerUrl}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {trackerId}
                            </a>{' '}
                          </React.Fragment>
                        ) : null}
                        {message}
                      </div>
                      <div>
                        {date}, <a href={prUrl}>{pr}</a>, {hash}
                      </div>
                    </div>
                  </div>
                ),
              )}
          </div>
        </Expander>
        <Expander areChildrenPadded={true} title="Sitemap">
          <SitemapNode data={sitemap} />
        </Expander>
        <Expander areChildrenPadded={true} title="Config">
          <table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(environmentVariables).map(([key, value]) => (
                <tr key={key}>
                  <td>
                    <code>{key}</code>
                  </td>
                  <td>
                    <code>{value}</code>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Expander>
        <Expander areChildrenPadded={true} title="Data">
          <table>
            <thead>
              <tr>
                <th>System</th>
                <th>Key</th>
                <th>Size</th>
                <th>Hash</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(data).map(([system, value]) => (
                <React.Fragment key={system}>
                  {Object.entries(value).map(
                    ([file, { bytes, data, hash, humanReadableSize }]) => (
                      <tr key={file}>
                        <td>{system}</td>
                        <td>
                          <button
                            onClick={showData(data)}
                            style={{
                              background: 'transparent',
                              border: 0,
                              fontSize: '1rem',
                              margin: '0 .5rem 0 0',
                              padding: 0,
                            }}
                          >
                            🔍
                          </button>
                          <code>{file}</code>
                        </td>
                        <td>
                          <code data-bytes={bytes}>{humanReadableSize}</code>
                        </td>
                        <td>
                          <code>{hash}</code>
                        </td>
                      </tr>
                    ),
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </Expander>
        <Expander areChildrenPadded={true} title="Tracked Contentful Types">
          <table className="web-admin-page__table">
            <tr>
              <th>ID</th>
              <th>Type</th>
              <th>Fields</th>
            </tr>
            {contentful.map(([contentTypeId, type, fields]) => (
              <tr key={contentTypeId} className="web-admin-page__table-row">
                <th>{contentTypeId}</th>
                <td>{type}</td>
                <td>{fields.join(', ')}</td>
              </tr>
            ))}
          </table>
        </Expander>
        <div className="web-admin-page__footer">
          <div className="web-admin-page__footer-section">
            <Text theme={Text.themes.emphasis}>Data last generated at:</Text>
            <Text>
              <time dateTime={dateTimeIso}>{dataBuildLocalTime}</time>
            </Text>
          </div>
          <div className="web-admin-page__footer-section">
            <Text theme={Text.themes.emphasis}>Application version:</Text>
            <Text>
              <code>{currentCommitHash}</code>
            </Text>
          </div>
        </div>
      </ContentContainer>
    </Layout>
  );
};

export default WebAdminPage;
