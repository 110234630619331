import React from 'react';

import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ConfirmationSummary from 'root/client/components/confirmation-summary/confirmation-summary';
import ContentContainer from 'root/client/components/content-container/content-container';
import Message from 'root/client/components/message/message';
import Text from 'root/client/components/text/text';

import { DropInConfirmationPage as Props } from './drop-in-confirmation-page.types';

const DropInConfirmationPage: React.FunctionComponent<Props> = ({
  description,
  errors = [],
  greeting,
  layout,
  summary,
  title,
}) => (
  <CleanLayout {...layout}>
    <ContentContainer className="drop-in-confirmation-page">
      <div>
        <div className="drop-in-confirmation-page__title">
          <Text
            elementName="h1"
            size={Text.sizes.header2}
            variant={Text.variants.hero}
          >
            {title}
          </Text>
        </div>
      </div>

      {errors.map(error => (
        <div className="drop-in-confirmation-page__section">
          <Message type={Message.types.error}>{error}</Message>
        </div>
      ))}
      {summary ? (
        <div className="drop-in-confirmation-page__section">
          <ConfirmationSummary {...summary} />
        </div>
      ) : null}

      <div className="drop-in-confirmation-page__text">
        <Text elementName="div">{description}</Text>
        <Text
          elementName="div"
          size={Text.sizes.header3}
          variant={Text.variants.hero}
        >
          {greeting}
        </Text>
      </div>
    </ContentContainer>
  </CleanLayout>
);

export default DropInConfirmationPage;
