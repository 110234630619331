import { Document } from '@contentful/rich-text-types';

export const themes = {
  intro: {
    introTheme: true,
  },
} as const;

export type Theme = Partial<(typeof themes)[keyof typeof themes]>;

export type ChildClassNames = {
  image?: string;
};

export type RichText = {
  className?: string;
  childClassNames?: ChildClassNames;
  content?: Document;
  theme?: Theme;
};
