import React, { useEffect, useState } from 'react';
import Rtc from 'react-tiny-collapse';

import { PropsType } from '../../type-utils';

type Props = PropsType<typeof Rtc>;

const Collapse: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  duration,
  ...props
}) => {
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);

  useEffect(() => {
    setPrefersReducedMotion(
      window.matchMedia('(prefers-reduced-motion: reduce)').matches,
    );
  });

  return <Rtc {...props} duration={prefersReducedMotion ? 0 : duration} />;
};

export default Collapse;
