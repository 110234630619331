import cn from 'classnames';
import React from 'react';

import ContentContainer from '../content-container/content-container';
import LinkButton from '../link-button/link-button';
import Text from '../text/text';

import { Color, ContentSection as Props } from './content-section.types';

const ContentSection: React.FunctionComponent<
  React.PropsWithChildren<Props>
> & {
  colors: typeof Color;
} = ({ actions, children, color, tall, title }) => (
  <div
    className={cn('content-section', {
      [`content-section--${color}`]: color,
      'content-section--tall': tall,
    })}
  >
    <ContentContainer>
      {title ? (
        <div className="content-section__title">
          <Text size={Text.sizes.header3} variant={Text.variants.hero}>
            {title}
          </Text>
        </div>
      ) : null}
      <div className="content-section__children">{children}</div>
      {actions.length ? (
        <div className="content-section__actions">
          {actions.map((action, index) => (
            <div key={action.href}>
              <LinkButton
                {...action}
                theme={
                  index ? LinkButton.themes.ctaSecondary : LinkButton.themes.cta
                }
              />
            </div>
          ))}
        </div>
      ) : null}
    </ContentContainer>
  </div>
);

ContentSection.colors = Color;

export default ContentSection;
