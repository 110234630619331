import React from 'react';

import CheckoutHeader from 'root/client/components/checkout-header/checkout-header';
import CheckoutSteps from 'root/client/components/checkout-steps/checkout-steps';
import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ContentContainer from 'root/client/components/content-container/content-container';
import ProductOptionCards from 'root/client/components/product-option-cards/product-option-cards';

import { CheckoutSelectMembershipPage as Props } from './checkout-select-membership-page.types';

const CheckoutSelectMembershipPage: React.FunctionComponent<Props> = ({
  checkoutSteps,
  layout,
  products,
  title,
}) => (
  <CleanLayout {...layout}>
    <ContentContainer>
      <CheckoutHeader title={title} />
      <CheckoutSteps {...checkoutSteps} />
    </ContentContainer>
    {products ? <ProductOptionCards {...products} expanded /> : null}
  </CleanLayout>
);

export default CheckoutSelectMembershipPage;
