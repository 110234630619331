import React from 'react';

import ArticleCard from 'root/client/components/article-card/article-card';
import { BlockType } from 'root/shared/content-block-helpers';
import Carousel from 'root/client/components/carousel/carousel';
import ComponentList from 'root/client/components/component-list/component-list';
import ContentElement from 'root/client/components/content-element/content-element';
import CTABanner from 'root/client/components/cta-banner/cta-banner';
import NavigationCard from 'root/client/components/navigation-card/navigation-card';
import ProductOptionCard from 'root/client/components/product-option-card/product-option-card';
import SplitInfoModule from 'root/client/components/split-info-module/split-info-module';
import TextBlock from 'root/client/components/text-block/text-block';

import ContentBlock from './content-block';
import type { ContentBlocks as ContentBlocksProps } from './content-blocks.types';

const ContentBlocks: React.FC<ContentBlocksProps> = ({ blocks }) => (
  <div className="content-blocks">
    <div className="content-blocks__blocks">
      {blocks.map(block => {
        switch (block.type) {
          case BlockType.ArticleCardCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={ArticleCard} />
              </ContentBlock>
            );
          }
          case BlockType.ArticleCardComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList {...block.props} entryRenderer={ArticleCard} />
              </ContentBlock>
            );
          }
          case BlockType.ContentElementCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={ContentElement} />
              </ContentBlock>
            );
          }
          case BlockType.ContentElementComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList
                  {...block.props}
                  entryRenderer={ContentElement}
                />
              </ContentBlock>
            );
          }
          case BlockType.CTABanner: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <CTABanner {...block.props} />
              </ContentBlock>
            );
          }
          case BlockType.NavigationCardCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={NavigationCard} />
              </ContentBlock>
            );
          }
          case BlockType.NavigationCardComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList
                  {...block.props}
                  entryRenderer={NavigationCard}
                />
              </ContentBlock>
            );
          }
          case BlockType.ProductOptionCardCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={ProductOptionCard} />
              </ContentBlock>
            );
          }
          case BlockType.ProductOptionCardComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList
                  {...block.props}
                  entryRenderer={ProductOptionCard}
                />
              </ContentBlock>
            );
          }
          case BlockType.SplitInfoModule: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <SplitInfoModule {...block.props} />
              </ContentBlock>
            );
          }
          case BlockType.TextBlock: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <TextBlock {...block.props} />
              </ContentBlock>
            );
          }
          case BlockType.TextBlockCarousel: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <Carousel {...block.props} entryRenderer={TextBlock} />
              </ContentBlock>
            );
          }
          case BlockType.TextBlockComponentList: {
            return (
              <ContentBlock id={block.key} key={block.key} type={block.type}>
                <ComponentList {...block.props} entryRenderer={TextBlock} />
              </ContentBlock>
            );
          }
        }
      })}
    </div>
  </div>
);

export default ContentBlocks;
