import cn from 'classnames';
import React from 'react';

import { Link as Props, RestProps } from './link.types';

const Link: React.FunctionComponent<RestProps & Props> = ({
  children,
  className,
  href,
  text,
  title,
  ...rest
}) =>
  children || text ? (
    <a className={cn('link', className)} href={href} title={title} {...rest}>
      {text ? text : children ? children : null}
    </a>
  ) : null;

export default Link;
