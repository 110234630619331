import React from 'react';

import Tag from '../tag/tag';
import Text from '../text/text';

import { PtIntroTextCard as Props } from './pt-intro-text-card.types';

const PtIntroTextCard: React.FunctionComponent<Props> = ({
  description,
  level,
  name,
}) => (
  <div className="pt-intro-text-card">
    <div className="pt-intro-text-card__header">
      <div className="pt-intro-text-card__name">
        <Text
          elementName="h1"
          size={Text.sizes.header1}
          theme={Text.themes.emphasis}
        >
          {name}
        </Text>
      </div>
      <div className="pt-intro-text-card__level">
        <Tag {...level} />
      </div>
    </div>
    {description ? (
      <div className="pt-intro-text-card__description">
        <Text size={Text.sizes.large}>{description}</Text>
      </div>
    ) : null}
  </div>
);

export default PtIntroTextCard;
