import type { LinkButton } from '../link-button/link-button.types';

export enum Color {
  primary = 'primary',
  secondary = 'secondary',
}

export type ContentSection = {
  actions: LinkButton[];
  color?: Color;
  tall?: boolean;
  title?: string;
};
