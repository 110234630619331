import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';

import renderOptions from './render-options';
import { RichText as Props, themes } from './rich-text.types';

const RichText: React.FunctionComponent<React.PropsWithChildren<Props>> & {
  themes: typeof themes;
} = ({ className, childClassNames, content, theme = {} }) => {
  return (
    <div className={['rich-text', className].join(' ')}>
      {content
        ? documentToReactComponents(
            content,
            renderOptions(childClassNames, theme),
          )
        : null}
    </div>
  );
};

RichText.themes = themes;

export default RichText;
