import Forward18 from '@sats-group/icons/18/forward';
import React from 'react';

import Link from 'root/client/components/link/link';
import Text from 'root/client/components/text/text';

import { ClubListItem as Props } from './club-list-item.types';

const ClubListItem: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  address,
  name,
  link,
}) => {
  return (
    <div className="club-list-item">
      <Link href={link} className="club-list-item__link">
        <div className="club-list-item__link-content">
          <div className="club-list-item__name">
            <Text elementName="span" size={Text.sizes.basic}>
              {name}
            </Text>
          </div>
          <Forward18 />
        </div>
      </Link>
      <div className="club-list-item__address">
        <Text size={Text.sizes.basic}>{address}</Text>
      </div>
    </div>
  );
};

export default ClubListItem;
