import ArrowDown24 from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React from 'react';

import BreakoutContainer from 'root/client/components/break-out-container/break-out-container';
import Collapse from 'root/client/components/collapse/collapse';
import ContentContainer from 'root/client/components/content-container/content-container';
import CustomerServiceBanner from 'root/client/components/customer-service-banner/customer-service-banner';
import Image from 'root/client/components/image/image';
import Layout from 'root/client/components/layout/layout';
import Link from 'root/client/components/link/link';
import LinkCards from 'root/client/components/link-cards/link-cards';
import RichText from 'root/client/components/rich-text/rich-text';
import Text from 'root/client/components/text/text';
import useToggle from 'root/client/hooks/use-toggle';

import { AboutFreshPage as Props } from './about-fresh-page.types';

const AboutFreshPage: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({
  body,
  collapse,
  collapsibleContent,
  contactUs,
  expand,
  image,
  intro,
  layout,
  related,
  title,
}) => {
  const [isExpanded, toggleIsExpanded] = useToggle(false);

  return (
    <Layout {...layout}>
      <div className="about-fresh-page">
        <ContentContainer>
          <div className="about-fresh-page__content">
            <div className="about-fresh-page__title-header">
              <Text
                elementName="h1"
                size={Text.sizes.header2}
                variant={Text.variants.hero}
              >
                {title}
              </Text>
              <Link
                href="#contact-us"
                className="about-fresh-page__contact-us-link"
              >
                <Text size={Text.sizes.header3}>{contactUs.title}</Text>
              </Link>
            </div>

            <Text size={Text.sizes.large}>{intro}</Text>
            <RichText {...body} />
            <Collapse
              isOpen={isExpanded}
              className="about-fresh-page__collapsible-content"
            >
              <RichText {...collapsibleContent} />
            </Collapse>
            <button
              className="about-fresh-page__trigger"
              onClick={toggleIsExpanded}
            >
              <Text size={Text.sizes.basic}>
                {isExpanded ? collapse : expand}
              </Text>
              <div
                className={cn('about-fresh-page__trigger-icon', {
                  'about-fresh-page__trigger-icon--active': isExpanded,
                })}
              >
                <ArrowDown24 />
              </div>
            </button>
          </div>
          {image ? (
            <BreakoutContainer>
              <div className="about-fresh-page__image">
                <Image aspectRatio={Image.aspectRatios.fill} {...image} />
              </div>
            </BreakoutContainer>
          ) : null}
        </ContentContainer>
        {related ? (
          <div className="about-fresh-page__related">
            <ContentContainer>
              <LinkCards {...related} />
            </ContentContainer>
          </div>
        ) : null}
        <div id="contact-us">
          <CustomerServiceBanner {...contactUs} />
        </div>
      </div>
    </Layout>
  );
};

export default AboutFreshPage;
