import React from 'react';

import Tags from '../tags/tags';
import Text from '../text/text';

import { PtInfoTextCard as Props } from './pt-info-text-card.types';

const PtInfoTextCard: React.FunctionComponent<Props> = ({ items }) => (
  <div className="pt-info-text-card">
    {items.map(item => (
      <div className="pt-info-text-card__item" key={item.title}>
        <div className="pt-info-text-card__title">
          <Text size={Text.sizes.header3} theme={Text.themes.emphasis}>
            {item.title}
          </Text>
        </div>
        {item.tags.tags.length ? (
          <div className="pt-info-text-card__tags">
            <Tags {...item.tags} theme="secondary" />
          </div>
        ) : null}
        <div className="pt-info-text-card__description">
          <Text>{item.description}</Text>
        </div>
      </div>
    ))}
  </div>
);

export default PtInfoTextCard;
