import React from 'react';

import ColorBlockLayout from 'root/client/components/color-block-layout/color-block-layout';
import ImageWithTextLinkCard from 'root/client/components/image-with-text-link-card/image-with-text-link-card';
import Layout from 'root/client/components/layout/layout';
import Link from 'root/client/components/link/link';
import TextModule from 'root/client/components/text-module/text-module';

import { WorkoutOfferPage as Props } from './workout-offer-page.types';

const WorkoutOfferPage: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ content, layout, related, previousPage }) => {
  return (
    <Layout {...layout}>
      <div className="workout-offer-page">
        <ColorBlockLayout>
          <ColorBlockLayout.Block
            color={ColorBlockLayout.colors.primary}
            padding={ColorBlockLayout.paddings.allsides}
          >
            <div>
              {previousPage && (
                <div className="workout-offer-page__previous-page">
                  <Link {...previousPage} />
                </div>
              )}
              <TextModule {...content} />
            </div>
          </ColorBlockLayout.Block>
          <ColorBlockLayout.Block
            color={ColorBlockLayout.colors.secondary}
            padding={ColorBlockLayout.paddings.allsides}
          >
            <div>
              {related && (
                <ul className="workout-offer-page__items">
                  {related.map(offer => (
                    <li className="workout-offer-page__item" key={offer.title}>
                      <ImageWithTextLinkCard {...offer} />
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </ColorBlockLayout.Block>
        </ColorBlockLayout>
      </div>
    </Layout>
  );
};

export default WorkoutOfferPage;
