import Arrow24 from '@sats-group/icons/24/arrow-down';
import cn from 'classnames';
import React, { useState } from 'react';

import BreakOutContainer from '../break-out-container/break-out-container';
import Collapse from '../collapse/collapse';

import CheckoutStep from './checkout-step';
import { CheckoutSteps as Props } from './checkout-steps.types';

const stepsId = '20b3d02c-e025-4b6d-b06a-64edf42c3f03';

const CheckoutSteps: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ currentStep, steps = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  if (!steps.length) {
    return null;
  }

  return (
    <div className="checkout-steps">
      <BreakOutContainer>
        <div className="checkout-steps__actions">
          <div className="checkout-steps__current">
            <CheckoutStep {...currentStep} />
          </div>
          <button
            className="checkout-steps__trigger"
            onClick={toggle}
            aria-expanded={isOpen}
            aria-controls={stepsId}
          >
            <div
              className={cn('checkout-steps__trigger-icon', {
                'checkout-steps__trigger-icon--flipped': isOpen,
              })}
            >
              <Arrow24 />
            </div>
          </button>
        </div>
      </BreakOutContainer>
      <Collapse
        className="checkout-steps__collapse"
        isOpen={isOpen}
        componentProps={{ id: stepsId }}
      >
        <ol className="checkout-steps__list">
          {steps.map(step => (
            <li className="checkout-steps__item" key={step.number}>
              <CheckoutStep {...step} />
            </li>
          ))}
        </ol>
      </Collapse>
    </div>
  );
};

export default CheckoutSteps;
