import React from 'react';

import Band from 'root/client/components/band/band';
import ContentContainer from 'root/client/components/content-container/content-container';
import ExpandableText from 'root/client/components/expandable-text/expandable-text';
import Layout from 'root/client/components/layout/layout';
import LinkButton from 'root/client/components/link-button/link-button';
import PageHeader from 'root/client/components/page-header/page-header';
import Text from 'root/client/components/text/text';

import { CustomerServiceCategory as Props } from './customer-service-category.types';

const CustomerServiceCategory: React.FunctionComponent<Props> = ({
  header,
  layout,
  otherCategories,
  otherCategoriesTitle,
  questions,
}) => (
  <Layout {...layout} theme={Layout.themes.primary}>
    <div className="customer-service-category">
      <PageHeader {...header} contentWidth={ContentContainer.widths.medium} />
      <div className="customer-service-category__body">
        <ContentContainer width={ContentContainer.widths.medium}>
          <div className="customer-service-category__questions">
            {questions.map(entry => (
              <div key={entry.title}>
                <ExpandableText {...entry} />
              </div>
            ))}
          </div>
        </ContentContainer>
        <Band contentWidth={ContentContainer.widths.medium} theme="secondary">
          <aside className="customer-service-category__other-categories">
            <Text
              elementName="h2"
              size={Text.sizes.header2}
              theme={Text.themes.emphasis}
              variant={Text.variants.hero}
            >
              {otherCategoriesTitle}
            </Text>
            <div className="customer-service-category__categories">
              {otherCategories.map(category => (
                <LinkButton
                  {...category}
                  key={category.href}
                  size={LinkButton.sizes.small}
                  theme={LinkButton.themes.secondary}
                />
              ))}
            </div>
          </aside>
        </Band>
      </div>
    </div>
  </Layout>
);

export default CustomerServiceCategory;
