import Forward18 from '@sats-group/icons/18/forward';
import React from 'react';

import Image from 'root/client/components/image/image';
import Link from 'root/client/components/link/link';
import Text from 'root/client/components/text/text';

import { ImageWithTextLinkCard as Props } from './image-with-text-link-card.types';

const ImageWithTextLinkCard: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ summary, href, image, title }) => (
  <div className="image-with-text-link-card">
    <Link href={href} className="image-with-text-link-card__link">
      <div className="image-with-text-link-card__image-wrapper">
        <Image aspectRatio={Image.aspectRatios.fill} {...image} />
      </div>
      <div className="image-with-text-link-card__text-wrapper">
        <div className="image-with-text-link-card__title-wrapper">
          <div className="image-with-text-link-card__title">
            <Text size={Text.sizes.basic} theme={Text.themes.medium}>
              {title}
            </Text>
          </div>
          <div>
            <Forward18 />
          </div>
        </div>
        <div className="image-with-text-link-card__summary-wrapper">
          <div className="image-with-text-link-card__summary">
            <Text size={Text.sizes.small}>{summary}</Text>
          </div>
        </div>
      </div>
    </Link>
  </div>
);

export default ImageWithTextLinkCard;
