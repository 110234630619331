import React from 'react';

import Text from '../text/text';

import { TextArea as Props } from './text-area.types';

const TextArea: React.FunctionComponent<Props> = ({
  id,
  label,
  name,
  required,
  rows,
}) => (
  <div className="text-area">
    <label className="text-area__label" htmlFor={id}>
      <Text>
        {label}
        {required ? <span aria-hidden={true}> *</span> : null}
      </Text>
    </label>
    <textarea
      className="text-area__element"
      id={id}
      name={name}
      required={required}
      rows={rows}
    />
  </div>
);

export default TextArea;
