import React from 'react';

import Button from '../button/button';
import HiddenInput from '../hidden-input/hidden-input';
import Text from '../text/text';
import TextField from '../text-field/text-field';

import { DiscountCodeInput as Props } from './discount-code-input.types';

const DiscountCodeInput: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ endpoint, text, title, hiddenInput, input, submitLabel }) => (
  <form className="discount-code-input" action={endpoint} method="POST">
    <HiddenInput {...hiddenInput} />
    <div className="discount-code-input__texts">
      <Text size={Text.sizes.large}>{title}</Text>
      <Text elementName="p" size={Text.sizes.small}>
        {text}
      </Text>
    </div>
    <div>
      <TextField {...input} required wide />
    </div>
    <div className="discount-code-input__actions">
      <Button
        size={Button.sizes.small}
        text={submitLabel}
        theme={Button.themes.secondary}
        type="submit"
      />
    </div>
  </form>
);

export default DiscountCodeInput;
