import Check24 from '@sats-group/icons/24/check';
import cn from 'classnames';
import React from 'react';

import Text from '../text/text';

import { CheckoutStep as Props } from './checkout-steps.types';

const CheckoutStep: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  isComplete,
  isCurrent,
  href,
  number,
  selectedOption,
  text,
}) =>
  React.createElement(
    isComplete && href ? 'a' : 'div',
    {
      className: cn('checkout-step', {
        'checkout-step--future': !isComplete && !isCurrent,
      }),
      href,
    },
    <React.Fragment>
      <div
        aria-hidden
        className={cn('checkout-step__number', {
          'checkout-step__number--complete': isComplete,
          'checkout-step__number--current': isCurrent,
        })}
      >
        {isComplete ? (
          <Check24 />
        ) : (
          <Text size={Text.sizes.basic}>{number}</Text>
        )}
      </div>
      <div className="checkout-step__step-name">
        <Text elementName="div" size={Text.sizes.basic}>
          {text}
        </Text>
        {selectedOption && (
          <Text elementName="div" size={Text.sizes.interface}>
            {selectedOption}
          </Text>
        )}
      </div>
    </React.Fragment>,
  );

export default CheckoutStep;
