import React, { InputHTMLAttributes } from 'react';

import { Radio as Props } from './radio.types';

type RestProps = Omit<InputHTMLAttributes<HTMLInputElement>, keyof Props>;

const Radio: React.FunctionComponent<RestProps & Props> = ({
  defaultChecked,
  isRequired,
  label,
  name,
  value,
  ...rest
}) => {
  return (
    <label className="radio">
      <input
        name={name}
        required={isRequired}
        type="radio"
        value={value}
        defaultChecked={defaultChecked}
        {...rest}
      />
      <div className="radio__label">
        <span className="radio__fake-element"></span>
        <span className="radio__label">{label}</span>
      </div>
    </label>
  );
};

export default Radio;
