import cn from 'classnames';
import React from 'react';

import Text from '../text/text';
import VisuallyHidden from '../visually-hidden/visually-hidden';

import { TextField as Props } from './text-field.types';

/**
 * Spec: https://www.figma.com/file/CemEcLe6DpoD5ELrFqlwZ7/shared-web-components?node-id=7670%3A15943
 */
const TextField: React.FunctionComponent<Props> = ({
  defaultValue,
  help,
  icon,
  id,
  label,
  naked,
  name,
  placeholder,
  required,
  type = 'text',
  wide,
  ...rest
}) => (
  <div className="text-field">
    {naked ? (
      <VisuallyHidden>
        <label htmlFor={id}>{label}</label>
      </VisuallyHidden>
    ) : (
      <label className="text-field__label" htmlFor={id}>
        <Text size={Text.sizes.basic}>
          {label}
          {required ? ' *' : null}
        </Text>
      </label>
    )}
    <div className="text-field__input-wrapper">
      {icon ? <div className="text-field__icon">{icon}</div> : null}
      <input
        className={cn('text-field__input', {
          'text-field__input--w-icon': icon,
          'text-field__input--wide': wide,
        })}
        defaultValue={defaultValue}
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        type={type}
        {...rest}
      />
    </div>
    {help ? (
      <div className="text-field__help">
        <Text elementName="div" size={Text.sizes.interface}>
          {help}
        </Text>
      </div>
    ) : null}
  </div>
);

export default TextField;
