import React from 'react';

import { HiddenInput as Props } from './hidden-input.types';

const HiddenInput: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  name,
  value,
}) => <input name={name} type="hidden" value={value} />;

export default HiddenInput;
