import { Footer } from 'root/client/components/footer/footer.types';
import { Header } from 'root/client/components/header/header.types';
import { MessageList } from 'root/client/components/message-list/message-list.types';

export enum Theme {
  primary = 'primary',
}

export type Layout = {
  footer?: Footer;
  header: Header;
  messageList: MessageList;
  theme?: Theme;
};
