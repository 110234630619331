import cn from 'classnames';
import React from 'react';

import { MessageType } from 'root/client/ts/messages.types';

import { Message as Props } from './message.types';

const types: Record<MessageType, MessageType> = {
  error: 'error',
  info: 'info',
  success: 'success',
};

const Message: React.FunctionComponent<React.PropsWithChildren<Props>> & {
  types: typeof types;
} = ({ children, text, type }) => (
  <div className={cn('message', { [`message--${type}`]: type })}>
    {children ? children : text}
  </div>
);

Message.types = types;

export default Message;
