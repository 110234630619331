import cn from 'classnames';
import React from 'react';

import Image from '../image/image';
import Text from '../text/text';

import type { ContentElement as Props } from './content-element.types';

const ContentElement: React.FunctionComponent<Props> = ({
  description,
  image,
  small,
  tall,
  title,
}) => (
  <div className={cn('content-element', { 'content-element--tall': tall })}>
    <div
      className={cn('content-element__inner', {
        'content-element__inner--small': small,
      })}
    >
      <div className="content-element__image">
        <Image {...image} aspectRatio={Image.aspectRatios.fill} />
        <div className="content-element__image-minimum" />
      </div>
      <div className="content-element__content">
        <Text
          elementName="h3"
          size={Text.sizes.header3}
          theme={Text.themes.emphasis}
          variant={Text.variants.hero}
          tight
        >
          {title}
        </Text>
        <Text elementName="p" tight>
          {description}
        </Text>
      </div>
    </div>
  </div>
);

export default ContentElement;
