import React from 'react';

import Text from '../text/text';

import { NavigationCard as Props } from './navigation-card.types';

const NavigationCard: React.FunctionComponent<Props> = ({
  description,
  icon,
  href,
  title,
}) => (
  <a className="navigation-card" href={href}>
    <div className="navigation-card__icon">
      <div dangerouslySetInnerHTML={{ __html: icon }} />
    </div>
    <div className="navigation-card__text">
      <div className="navigation-card__title">
        <Text size={Text.sizes.large} theme={Text.themes.emphasis}>
          {title}
        </Text>
      </div>
      <div className="navigation-card__description">
        <Text>{description}</Text>
      </div>
    </div>
  </a>
);

export default NavigationCard;
