import { createElement } from 'react';
import { hydrateRoot } from 'react-dom/client';

import Pages from './codegen/pages.codegen';
import './assets/favicons/apple-touch-icon.png'; // NOTE: We import this so its URL ends up in the manifest.
import './assets/favicons/f.svg'; // NOTE: We import this so its URL ends up in the manifest.
import './assets/favicons/favicon.ico'; // NOTE: We import this so its URL ends up in the manifest.

const { component: componentName, props } = window.__INITIAL_DATA__ || {};

(() => {
  if (!componentName) {
    console.error('No component name found.');
    return;
  }

  const component = Pages[componentName];
  if (!component) {
    console.error(`Component "${componentName}" not found.`);
    return;
  }

  if (!props) {
    console.error('No props found.');
    return;
  }

  const mountPoint = document.getElementById('mount-point');
  if (!mountPoint) {
    console.error('No mount point found.');
    return;
  }

  hydrateRoot(mountPoint, createElement(component, props));
  document.documentElement.setAttribute('data-hydrated', '');
})();
