import type { ValueOf } from 'type-fest';

export const sizes = {
  header1: 'header1',
  header2: 'header2',
  header3: 'header3',
  large: 'large',
  basic: 'basic',
  small: 'small',
  interface: 'interface',
} as const;

export const themes = {
  emphasis: 'emphasis',
  italic: 'italic',
  medium: 'medium',
  normal: 'normal',
} as const;

export const variants = {
  content: 'content',
  contrast: 'contrast',
  hero: 'hero',
} as const;

export type Text = {
  elementName?: string;
  size?: ValueOf<typeof sizes>;
  theme?: ValueOf<typeof themes>;
  tight?: boolean;
  variant?: ValueOf<typeof variants>;
};
