import React from 'react';

import VisuallyButton from '../visually-button/visually-button';
import { sizes, themes } from '../visually-button/visually-button.types';

import type { Button as Props } from './button.types';

const Button: React.FunctionComponent<Props> & {
  sizes: typeof sizes;
  themes: typeof themes;
} = ({ type = 'button', ...rest }) => (
  <VisuallyButton elementName="button" role="button" type={type} {...rest} />
);

Button.sizes = VisuallyButton.sizes;
Button.themes = VisuallyButton.themes;

export default Button;
