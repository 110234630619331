import React from 'react';

import CleanLayout from 'root/client/components/clean-layout/clean-layout';
import ConfirmationSummary from 'root/client/components/confirmation-summary/confirmation-summary';
import ContentContainer from 'root/client/components/content-container/content-container';
import ElementInterpolator from 'root/client/components/element-interpolator/element-interpolator';
import Message from 'root/client/components/message/message';
import Text from 'root/client/components/text/text';

import { CheckoutConfirmationPage as Props } from './checkout-confirmation-page.types';

const CheckoutConfirmationPage: React.FunctionComponent<Props> = ({
  error,
  layout,
  messages = [],
  signOff,
  summary,
  title,
}) => (
  <CleanLayout {...layout}>
    <ContentContainer className="checkout-confirmation-page">
      <div className="checkout-confirmation-page__section">
        <div className="checkout-confirmation-page__title">
          <Text
            elementName="h1"
            size={Text.sizes.header2}
            variant={Text.variants.hero}
          >
            {title}
          </Text>
        </div>
      </div>
      {error ? (
        <div className="checkout-confirmation-page__section">
          <Message type={Message.types.error}>{error}</Message>
        </div>
      ) : null}
      {summary ? (
        <div className="checkout-confirmation-page__section">
          <ConfirmationSummary {...summary} />
        </div>
      ) : null}
      {messages.map(({ text, href, hrefText }) => (
        <div className="checkout-confirmation-page__section" key={text}>
          <Text size={Text.sizes.header3}>
            {href && hrefText ? (
              <ElementInterpolator
                elements={{ link: <a href={href}>{hrefText}</a> }}
                template={text}
              />
            ) : (
              text
            )}
          </Text>
        </div>
      ))}
      {signOff ? (
        <div className="checkout-confirmation-page__section">
          <div className="checkout-confirmation-page__sign-off">
            <Text size={Text.sizes.header2}>{signOff}</Text>
          </div>
        </div>
      ) : null}
    </ContentContainer>
  </CleanLayout>
);

export default CheckoutConfirmationPage;
